import PublicPage from '../../shared/PublicPage/PublicPage';
import { LoginContainer } from './Login.styled';
import CleverLoginButton from './CleverLoginButton/CleverLoginButton';
import GoogleClassroomLoginButton from './GoogleClassroomLoginButton/GoogleClassroomLoginButton';
import ClasslinkLoginButton from './ClasslinkLoginButton/ClasslinkLoginButton';
import RockalinguaLoginButton from './RockalinguaLoginButton/RockalinguaLoginButton';
import { Button } from 'antd';
import useLogin from './useLogin.hook';
import Image from 'next/image';
import { motion, AnimatePresence } from 'framer-motion';
import { WithHugeDarkDropRightBg } from '@/shared/PublicPage/CustomBgPublicPage.styled';
import { useEffect, useRef } from 'react';
import { useWindowSize } from '@uidotdev/usehooks';
import { useMediaQuery } from 'react-responsive';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';

const loginText = 'Log In with';
const studentImage =
  process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
  '/img/login/01_LOGIN_student.png';
const teacherImage =
  process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
  '/img/login/03_LOGIN_teacher.png';
const studentSectionId = 'student-section';
const teacherSectionId = 'teacher-section';

export default function Login() {
  const { state, actions } = useLogin();
  const studentImagePosition = useRef(null);
  const size = useWindowSize();

  const isSmall = useMediaQuery({
    maxWidth: MediaQueryBreakpoints.md,
  });

  useEffect(() => {
    const studentSectionDiv = document.getElementById(studentSectionId);
    const teacherSectionDiv = document.getElementById(teacherSectionId);
    if (studentSectionDiv && teacherSectionDiv) {
      const studentImagePos = studentSectionDiv.getBoundingClientRect();
      const teacherImagePos = teacherSectionDiv.getBoundingClientRect();

      if (isSmall) {
        studentImagePosition.current = 0;
      } else {
        studentImagePosition.current = studentImagePos.x - teacherImagePos.x;
      }
    }
  }, [size, isSmall]);

  const isMode = (userType) => {
    if (state.userType === userType) {
      return 'confirmed';
    } else if (state.userType === null) {
      return 'initial';
    }

    return 'invisible';
  };

  return (
    <PublicPage StyledPublicPage={WithHugeDarkDropRightBg}>
      <AnimatePresence>
        <LoginContainer>
          <div className="login-content">
            <div className="login-sections">
              <LoginSection
                id={studentSectionId}
                image={studentImage}
                mode={isMode('student')}
                onConfirm={() => actions.setUserType('student')}
                onCancel={() => actions.setUserType(null)}
                text="I'm a student"
                positionToAnimate={studentImagePosition.current * -1}
                Buttons={StudentButtons}
              />

              <LoginSection
                id={teacherSectionId}
                image={teacherImage}
                mode={isMode('teacher')}
                onConfirm={() => actions.setUserType('teacher')}
                onCancel={() => actions.setUserType(null)}
                text="I'm a teacher"
                positionToAnimate={studentImagePosition.current}
                Buttons={TeacherButtons}
              />
            </div>
          </div>
        </LoginContainer>
      </AnimatePresence>
    </PublicPage>
  );
}

function LoginSection({
  id,
  mode = 'initial',
  onConfirm = () => {},
  onCancel = () => {},
  image,
  text,
  positionToAnimate,
  Buttons,
}) {
  return (
    <div id={id} className={`login-section ${mode}`}>
      {mode === 'invisible' ? null : (
        <div>
          <div className="login-actions-container">
            {mode === 'confirmed' ? <Buttons /> : null}
            <motion.div
              className={`image-container ${mode}`}
              animate={{
                x: mode === 'confirmed' ? positionToAnimate : 0,
              }}
            >
              <div className="image">
                <Image src={image} alt="Teacher" layout="fill" />
              </div>
            </motion.div>
          </div>

          {mode !== 'confirmed' && (
            <div>
              <Button
                onClick={onConfirm}
                size="large"
                type="primary"
                className="big-button"
              >
                {text}
              </Button>
            </div>
          )}
        </div>
      )}

      {mode == 'confirmed' ? (
        <Button onClick={onCancel} size="large" className="wrong-button">
          Go back
        </Button>
      ) : null}
    </div>
  );
}

function StudentButtons() {
  return (
    <motion.div
      key="student-buttons"
      className="buttons"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="login-button-container">
        <RockalinguaLoginButton isStudent />
        <div className="or">or</div>
      </div>
      <GoogleClassroomLoginButton text={loginText} isStudent />
      <CleverLoginButton text={loginText} />
      <ClasslinkLoginButton text={loginText} />
      {/* <Link href="/student/enrollment" passHref>
        <Button size="large">I have an enrollment code</Button>
      </Link> */}
    </motion.div>
  );
}

function TeacherButtons() {
  return (
    <motion.div
      className="buttons"
      key="teacher-buttons"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="login-button-container">
        <RockalinguaLoginButton />
        <div className="or">or</div>
      </div>

      <GoogleClassroomLoginButton text={loginText} />
      <CleverLoginButton text={loginText} />
      <ClasslinkLoginButton text={loginText} />
    </motion.div>
  );
}
