import useCustomHook from '@/hooks/useCustomHook';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function useLogin() {
  const router = useRouter();

  const { state, actions } = useCustomHook({
    defaultState: {
      userType: null,
    },
    actions: {
      setUserType: (userType) => {
        actions.setState({ userType });
      },
    },
  });

  useEffect(() => {
    if (router.isReady) {
      const { userType } = router.query;
      if (userType) {
        actions.setUserType(userType);
      }
    }
  }, [router.isReady]);

  return { state, actions };
}
