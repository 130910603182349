import Colors from '@/constants/Colors';
import styled from 'styled-components';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';

export const LoginContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;

  .login-content {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .login-sections {
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      align-items: center;

      .login-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .image {
          height: 35vh;
          width: 35vh;
          position: relative;
          margin-bottom: 20px;
        }

        h2 {
          color: ${Colors.text};
          text-align: center;
          font-size: 20px;
          margin-bottom: 0;
        }

        .login-actions-container {
          position: relative;
          display: flex;
          flex-direction: column;

          .image-container.confirmed {
            position: absolute;
          }
        }

        .buttons {
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 12px;
          margin-bottom: 16px;

          .login-button-container {
            z-index: 1;
            .or {
              margin-top: 10px;
              text-align: center;
              font-size: 16px;
            }
          }
          > * {
            width: 300px;
          }

          &.align-right {
            align-items: flex-end;
          }

          &.align-left {
            align-items: flex-start;
          }
        }

        .big-button {
          width: 100%;
        }
        .wrong-button {
          margin-top: 20px;
        }
      }
    }
  }

  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    .login-content {
      margin-top: 20px;
      .login-sections {
        flex-direction: column;
        justify-content: flex-start;

        .login-section {
          &.invisible {
            display: none;
          }

          .image {
            height: 50vw;
            width: 50vw;
          }

          .login-actions-container {
            flex-direction: column-reverse;
            .image-container.confirmed {
              position: initial;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }
`;
